import axios from 'axios'
import wx from 'weixin-js-sdk'
// import qs from 'qs';
// console.log(wx);

export default function getWXSign() {
    const href = window.location.href
    const str = href.substr(href.indexOf("?") + 1, href.length)
    const params = str.split('&')
    const code = params[0].split('=')[1]
    const openid = params[1].split('=')[1]
        // console.log(code);
        // var latitude;
        // var longitude;
        // alert(window.location.href)
    var urls = window.location.href
    return new Promise((resolve, reject) => {
        axios.post('/cloud/get/wx/data', {
                url: urls
            })
            .then(function(res) {
                const data = res.data.data //返回wx.config需要的参数
                    // console.log(data);
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
                    timestamp: data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: data.nonceStr, // 必填，生成签名的随机串
                    signature: data.signature, // 必填，签名，见附录1
                    jsApiList: [ // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                        "getLocation"
                    ]
                })
                wx.ready(function() {
                    wx.getLocation({
                        type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                        success: function(res) {
                            var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                            var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                            var speed = res.speed; // 速度，以米/每秒计
                            var accuracy = res.accuracy; // 位置精度
                            axios.get(`/cloud/get/location?openid=${openid}&code=${code}&latitude=${latitude}&longitude=${longitude}`).then(function(res) {
                                if (res.data.code === 200) {
                                    // alert(res.data.data.type)
                                    // alert(res.data.type)
                                    if (res.data.data.type == 1) {
                                        // document.body.removeChild(document.getElementById('Loading'));
                                        // window.location.href = "http://dwdata.cadayun.com/home?openid=" + res.data.data.enterpriseId
                                        window.location.href = `http://dwdata.cadayun.com/home?openid=${res.data.data.enterpriseId}&userId=${res.data.data.userId}`
                                    } else {
                                        // document.body.removeChild(document.getElementById('Loading'));
                                        alert('位置不在范围内')
                                        WeixinJSBridge.call("closeWindow");
                                    }
                                } else if (res.data.code === 400) {
                                    window.location.href = "http://dwdata.cadayun.com/Error_page"
                                } else {
                                    alert("参数过期,请重新扫码")
                                }

                            })
                        }
                    });

                    // console.log(latitude);
                    // console.log(longitude);
                    // wx.updateAppMessageShareData({
                    //         title: '', //标题
                    //         link: '', //分享链接 与对应的公众号域名一样
                    //         imgUrl: '', //分析图标
                    //         desc: '', //内容
                    //         success: function (res) {
                    //             console.log(res);
                    //         }
                    //     }),
                    // wx.getLocation({
                    //     type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                    //     success: function (res) {
                    //         var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                    //         var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                    //         var speed = res.speed; // 速度，以米/每秒计
                    //         var accuracy = res.accuracy; // 位置精度
                    //         alert(latitude)
                    //         alert(longitude)
                    //     }
                    // });

                    // wx.updateTimelineShareData({
                    //     title: '',
                    //     link: '',
                    //     imgUrl: '',
                    //     desc: '',
                    // })
                    resolve()
                })

                wx.error(function(res) {
                    console.log(res)
                        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                })
            })
            .catch(function(error) {
                reject(error + '参数错误')
            })
    })

}