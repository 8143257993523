<template>
  <div class="top_Header">
    <header class="headers">
      <img src="../../../public/image/1.png" alt="" />
      <img
        class="imgSrc"
        src="../../../public/image/yunshuju_logo.png"
        alt=""
      />
    </header>
    <section class="sects">
      <img src="../../../public/image/zi.png" alt="" />
    </section>
    <aside class="content">
      <img src="../../../public/image/编组 10.png" alt="" />
    </aside>

    <div class="bgphoto">
      <img src="../../assets/bg.png" alt="" />
    </div>
  </div>
</template>

<script>
import getWXSign from "../../utils/wx";
export default {
  name: "Home",
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
  watch: {},
  created() {
    var code = this.$route.query.code;
    var openid = this.$route.query.openid;

    if (code == null || code === "") {
      // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4cec5bc1787225ad&redirect_uri=http%3A%2F%2Fdwdata.cadayun.com&response_type=code&scope=snsapi_base&state=${openid}#wechat_redirect`;
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx36521ac394ab1d20&redirect_uri=http%3A%2F%2Fdwdata.cadayun.com&response_type=code&scope=snsapi_base&state=${openid}#wechat_redirect`;
    } else {
      getWXSign().then((res) => {
        console.log(res);
      });
    }
    // document.body.removeChild(document.getElementById("Loading"));
  },

  mounted() {},
};
</script>

<style scoped lang="scss">
.top_Header {
  width: 100%;
  height: 100%;

  // background-image: url('../../assets/bg.png');
  // background-repeat: no-repeat;
  .headers {
    display: flex;
    margin: 0px 27px;
    margin-top: 30px;
    align-items: center;

    .imgSrc {
      margin-left: 9px;
    }
  }

  .sects {
    width: 100%;
    height: 100%;
    margin: 0px 27px 45px;
    margin-top: 45px;
  }

  .content {
    width: 100%;
    height: 100%;
    margin: 0px 27px 95px;
  }

  .bgphoto {
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: -11;
    }
  }

  .btns {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 240px;

    button {
      width: 275px;
      height: 44px;
      background: #ffffff;
      border-radius: 28px;
      border: none;
      cursor: pointer;
      font-size: 22px;
      font-family: AlibabaPuHuiTiM;
      color: #005ae9;
      line-height: 44px;
    }
  }

  .foters {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;

    p {
      width: 268px;
      height: 44px;
      font-size: 16px;
      font-family: AlibabaPuHuiTiR;
      color: #ffffff;
      line-height: 22px;
      text-align: center;
    }
  }
}
</style>